import Form from "../../../assets/js/form";
import Common from "../../../assets/js/common";
import Cart from "./cart";

export default class ApplyCoupon extends Form {
	constructor() {
		super();
		this.formEl = "#js_apply-coupon-form";
		this.btnEl = "#js_apply-coupon-submit-btn";
		this.rules = {
			"coupon": {
				required: true
			}
		};
		this.initForm();
		this.onModalHidden();
		this.onClickApplyCouponLink();
		this.onClickRemoveCouponLink();
	}

	onClickApplyCouponLink() {
		$(document).on('click', ".js_apply-coupon-link", (e) => {
			e.preventDefault();
			const el = $(e.currentTarget);
			this.successCallback({
				code: el.attr("data-code")
			});
		});
	}

	onClickRemoveCouponLink() {
		$(document).on('click', ".js_remove-coupon-link", (e) => {
			e.preventDefault();

			const cart = JSON.parse(localStorage.getItem("cart"));

			if (cart) {

				if (cart.code) {
					delete cart.code;
				}

				localStorage.setItem("cart", JSON.stringify(cart));
				Cart.updateCart();
			}
		});
	}

	successCallback(data) {
		$("#js_apply-coupon-modal").modal("hide");

		const cart = JSON.parse(localStorage.getItem("cart"));

		if (cart) {
			cart.code = data.code;
			localStorage.setItem("cart", JSON.stringify(cart));
			Cart.updateCart();
		}
	}

	errorCallback() {
		this.validator[this.formEl].showErrors({
			code: "Invalid Coupon Code"
		});
	}

	onModalHidden() {
		$(document).on('hidden.bs.modal', "#js_apply-coupon-modal", () => {
			Common.resetForms(this.formEl);
		});
	}
}
