import Form from "../../../assets/js/form";

export default class ChangeMobileOtpForm extends Form {
	constructor() {
		super();
		this.formEl = "#js_change-mobile-otp-form";
		this.btnEl = "#js_change-mobile-otp-submit-btn";
		this.rules = {
			otp: {
				required: true,
				number: true,
				minlength: 6
			}
		};
		this.initForm();
		this.onClickResendOtp();
		this.onInputOtpInputs();
	}

	startTimer(duration) {
		if (window.changeMobileOtpInterval != undefined && window.changeMobileOtpInterval != 'undefined') {
			window.clearInterval(window.changeMobileOtpInterval);
		}
		var timer = duration, minutes, seconds;
		window.changeMobileOtpInterval = setInterval(() => {
			minutes = parseInt(timer / 60, 10);
			seconds = parseInt(timer % 60, 10);

			minutes = minutes < 10 ? "0" + minutes : minutes;
			seconds = seconds < 10 ? "0" + seconds : seconds;

			$(`${this.formEl} .js_otp-timer`).text(minutes + ":" + seconds);

			if (--timer < 0) {
				clearInterval(window.changeMobileOtpInterval);
			}
		}, 1000);
	}

	sendOtp() {
		$.ajax({
			url: '/auth/send-otp',
			method: 'POST',
			data: {
				phone: this.phone
			},
			dataType: 'JSON',
		})
	}

	onClickResendOtp() {
		$(document).on("click", `${this.formEl} .js_resend-otp`, e => {
			e.preventDefault()

			this.sendOtp()
			this.startTimer(300)
		})
	}

	onInputOtpInputs() {
		const otp = [];
		$(document).on("keyup", `${this.formEl} .otp-inputs input`, (e) => {
			const el = $(e.currentTarget);
			if (el.val()) {
				otp[el.index()] = el.val();
				if (otp.length == 6) {
					$(this.formEl+" input[name='otp']").val(otp.join(""));
				}
				el.next().focus();
			} else {
				$(this.formEl+" input[name='otp']").val("");
			}
		});
	}

	successCallback() {
		window.location.reload();
	}
}
