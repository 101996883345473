import Common from "../../../assets/js/common";

export default class Cart {
	constructor() {
		this.onStorageChange();
		this.onClickCartLink();
		this.onHoverCartLink();
		Cart.updateCart();
	}

	onClickCartLink() {
		$(document).on("click","#js_cart", (e) => {
			e.preventDefault();
			const el = $(e.currentTarget);
			if ($("#js_cart-dropdown #js_cart-items").length) {
				window.location.href = el.attr("data-url");
			} else {
				Common.growlNotification({
					title: 'Add Fish Species to Cart',
					description: 'Please add some Fish Species to your cart.',
					type: 'warning',
					position: 'top-center',
					closeTimeout: 2000
				});
			}
		});
	}

	onHoverCartLink() {
		$(document).on("mouseenter", "#js_cart", (e) => {
			e.preventDefault();
			$("#js_cart-dropdown").removeClass("d-none");
		});

		$(document).on("mouseleave","#js_cart-dropdown", (e) => {
			e.preventDefault();

			if ($(e.relatedTarget).hasClass("loadingoverlay")) {
				return;
			}

			setTimeout(() => {
				$("#js_cart-dropdown").addClass("d-none");
			}, 300);
		});
	}

	onStorageChange() {
		window.addEventListener('storage', (e) => {
			if (e.key == "cart") {
				Cart.updateCart();
			}
		});
	}

	static initDiscounts() {
		if ($('#js_apply-coupon-modal').length) {
			Common.loadContent({
				url: '/orders/discounts?' + $.param({
					location_id: localStorage.getItem('selectedStallId'),
					only_coupon: 1,
					is_active: 1,
				}),
				method: 'POST',
				data: localStorage.getItem('cart'),
				contentType: "application/json",
				processData: false,
				parent: $("#js_discounts"),
				success: () => {
					$('[data-toggle="popover"]').popover()
				}
			});
		}
	}

	static loadCart(parent) {
		const cart = localStorage.getItem("cart");
		let url = "/orders/cart";
		let dropdown = true;

		if ($("#js_checkout-cart").length) {
			dropdown = false;
			url += "?checkout=1";
		}

		if (cart) {
			$('#js_cart-items').LoadingOverlay('show');

			Common.loadContent({
				url: url,
				method: "POST",
				data: cart,
				contentType: "application/json",
				processData: false,
				parent: parent,
				dropdown: dropdown,
				success: () => {
					const total = parseFloat($('#total').val())

					$('#js_cart-items').LoadingOverlay('hide')

					if (total <= 0) {
						$('.js_pay').addClass('disabled')
					} else if (total >= 1000) {
						$('.nav-link[data-target="#cod"]').addClass('disabled')
						$('#cod .js_pay').addClass('disabled')
					} else {
						$('.nav-link[data-target="#cod"]').removeClass('disabled')
						$('#cod .js_pay').removeClass('disabled')
					}

					Cart.initDiscounts()
				}
			});
		}
	}

	static updateCart() {
		const cart = JSON.parse(localStorage.getItem("cart"));

		if (cart) {
			let parent = $("#js_checkout-cart");

			$("#js_cart .cart-count").removeClass("d-none");
			$("#js_cart .cart-count").text(Object.keys(cart.items).length);

			if (!parent.length) {
				parent = $("#js_cart-dropdown");
			}

			Cart.loadCart(parent);
		} else {
			$("#js_cart .cart-count").addClass("d-none");
			$("#js_cart .cart-count").text(0);

			if ($("#js_checkout-cart").length) {
				window.location.href = "/";
			} else {
				if ($("#js_cart-dropdown #js_cart-items").length) {
					$("#js_cart-dropdown").html("");
					$(".cart-btn").find(".add").show();
					$(".cart-btn").find(".change").hide();
					$(".cart-btn").find(".qty").text(0);
				}
			}
		}
	}

	static addToLocalStorage(item, qty, location_id) {
		let cart = JSON.parse(localStorage.getItem("cart"));

		if (cart) {
			if (cart.location_id == location_id) {
				if (!cart.items[item.id]) {
					cart.items[item.id] = {};
				}

				cart.items[item.id]["id"] = item.id;
				cart.items[item.id]["qty"] = qty;
				cart.items[item.id]["weight"] = item.weight * qty;
			}
		} else {
			cart = {};
			cart.location_id = location_id;
			cart.items = {};
			cart.items[item.id] = {};
			cart.items[item.id]["id"] = item.id;
			cart.items[item.id]["qty"] = qty;
			cart.items[item.id]["weight"] = item.weight * qty;
		}

		localStorage.setItem("cart", JSON.stringify(cart));
		Cart.updateCart();
	}

	static removeFromLocalStorage(item_id) {
		const cart = JSON.parse(localStorage.getItem("cart"));

		if (cart && cart.items[item_id]) {
			delete cart.items[item_id];

			if (Object.keys(cart.items).length == 0) {
				localStorage.removeItem("cart");
			} else {
				localStorage.setItem("cart", JSON.stringify(cart));
			}
		}

		Cart.updateCart();
	}
}
