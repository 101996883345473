$.validator.addMethod(
	"regex",
	function(value, element, regexp) {
		var re = new RegExp(regexp);
		return this.optional(element) || re.test(value);
	},
	"Please check your input."
);

import Login from './login';
import Register from './register';
import Otp from './otp';
import ResetPassword from './reset_password';

const resetPassword = new ResetPassword();
const otp = new Otp(resetPassword);
new Login(otp);
new Register(otp);

function resetForms() {
	$("#js_auth-modal form").each((i,v) => {
		$(v)[0].reset();
		$(v).validate().resetForm();
	});
}

$(document).on('show.bs.modal', "#js_auth-modal", (e) => {
	const button = $(e.relatedTarget);
	const content = button.attr("data-content");
	if (content) {
		$("#js_auth-modal .modal-content").addClass("d-none");
		$(content).removeClass("d-none");
	} else {
		$("#js_auth-modal .modal-content").addClass("d-none");
		$("#js_login").removeClass("d-none");
	}
});

$(document).on('hidden.bs.modal', "#js_auth-modal", () => {
	resetForms();
});

$(document).on("click", ".js_auth-content-nav", (e) => {
	e.preventDefault();
	const el = $(e.currentTarget);
	const content = el.attr("data-content");
	if (content) {
		$("#js_auth-modal .modal-content").addClass("d-none");
		$(content).removeClass("d-none");
	}
})
