import Common from "../../../assets/js/common";
import GMaps from "gmaps";

export default class List {
	constructor() {
		this.chatSocket = null
		this.trackingSocket = null
		this.chatSocketInterval = null
		this.trackingSocketInterval = null

		if ($("#js_orders").length) {
			this.loadOrders();
			this.onClickOrderViewDetails();
			this.onClickTrackOrder();
			this.onClickHelp();
			this.initCreateConversation();
			this.initChatForm();
			this.initPagination();

			if ($("#js_track-order-modal").length) {
				this.onTrackOrderModalEvent();
				$("#js_track-order-modal").modal("show");
			}
		}
	}

	onTrackOrderModalEvent() {
		$(document).on('shown.bs.modal', "#js_track-order-modal", (e) => {
			this.initMap();
			this.initTrackingSocket($(e.currentTarget).data('order-id'));
		});

		$(document).on('hidden.bs.modal', "#js_track-order-modal", () => {
			$("#js_track-order-modal").remove();
			clearInterval(this.trackingSocketInterval);
			this.trackingSocket.close();
		});
	}

	initMap() {
		const deliveryLocation = JSON.parse($("#js_track-order-map").attr("data-obj"));
		const pickupLatLng = {
			lat: parseFloat(deliveryLocation.pickup_lat),
			lng: parseFloat(deliveryLocation.pickup_lng)
		};
		const dropoffLatLng = {
			lat: parseFloat(deliveryLocation.dropoff_lat),
			lng: parseFloat(deliveryLocation.dropoff_lng)
		};
		const runnerLatLng = deliveryLocation.runner_lat ? {
			lat: parseFloat(deliveryLocation.runner_lat),
			lng: parseFloat(deliveryLocation.runner_lng)
		} : null;

		this.map = new GMaps({
			div: '#js_track-order-map',
			lat: deliveryLocation.dropoff_lat,
			lng: deliveryLocation.dropoff_lng
		});

		this.map.addMarker({
			...pickupLatLng,
			icon: {
				url: '/images/store-marker.svg',
				size: { width: 42, height: 42 },
				scaledSize: { width: 42, height: 42 }
			}
		});

		this.map.addMarker({
			...dropoffLatLng,
			icon: {
				url: '/images/home-marker.svg',
				size: { width: 42, height: 42 },
				scaledSize: { width: 42, height: 42 }
			}
		});

		this.map.drawPolyline({
			path: [
				[ deliveryLocation.pickup_lat, deliveryLocation.pickup_lng ],
				[ deliveryLocation.dropoff_lat, deliveryLocation.dropoff_lng ]
			],
			strokeOpacity: 0,
			icons: [ {
				icon: {
					path: 'M 0,-1 0,1',
					strokeOpacity: 1,
					scale: 4
				},
				offset: '0',
				repeat: '20px'
			} ]
		});

		const bounds = [ pickupLatLng, dropoffLatLng ];

		if (runnerLatLng) {
			bounds.push(runnerLatLng);
			this.map.addMarker({
				...runnerLatLng,
				icon: {
					url: '/images/rider-marker.svg',
					size: { width: 30, height: 30 },
					anchor: { x: 0, y: 10 },
					scaledSize: { width: 30, height: 30 }
				}
			});
		}

		this.map.fitLatLngBounds(bounds);
	}

	initTrackingSocket(orderId) {
		const urlArr = window.location.href.split('/')

		clearInterval(this.trackingSocketInterval)
		this.trackingSocket = new WebSocket(`${
			urlArr[0] === 'https:' ? 'wss' : 'ws'
		}://${urlArr[2]}/orders/${orderId}`)

		this.trackingSocket.onmessage = (event) => {
			const data = JSON.parse(event.data)

			if (data.type === 'status_change') {
				$.ajax({
					url: `/orders/track/${orderId}`,
					method: 'POST',
					data: data.data,
					success: html => {
						html = $(html)
						$("#js_track-order-modal .modal-body").replaceWith(html.find('.modal-body'));
						this.initMap();
					},
					error: err => {
						return console.error(err)
					}
				})
			}
		}

		this.trackingSocket.onerror = err => console.error(err) // eslint-disable-line
		this.trackingSocketInterval = setInterval(() => {
			if (this.trackingSocket.readyState) {
				this.trackingSocket.send(JSON.stringify({ type: 'heartbeat' }))
			}
		}, 10000)
	}

	loadOrders() {
		Common.loadContent({
			url: "/orders",
			parent: $("#js_orders")
		});
	}

	onClickOrderViewDetails() {
		$(document).on("click",".js_order-detail", (e) => {
			e.preventDefault();
			const el = $(e.currentTarget);
			el.addClass("disabled running");
			Common.loadModalFormContent({
				url: el.attr("data-url"),
				success: () => {
					el.removeClass("disabled running");
				},
				error: () => {
					el.removeClass("disabled running");
				}
			});

		});
	}

	onClickTrackOrder() {
		$(document).on("click",".js_track-order", (e) => {
			e.preventDefault();
			const el = $(e.currentTarget);
			el.addClass("disabled running");
			Common.loadModalFormContent({
				url: el.attr("data-url"),
				bindEvents: false,
				success: () => {
					el.removeClass("disabled running");
				},
				error: () => {
					el.removeClass("disabled running");
				}
			});

		});
	}

	onClickHelp() {
		$(document).on("click", ".js_order-help", e => {
			e.preventDefault();

			const el = $(e.currentTarget);

			el.addClass("disabled running");
			Common.loadModalFormContent({
				url: el.attr("data-url"),
				bindEvents: false,
				success: () => {
					el.removeClass("disabled running");
				},
				error: () => {
					el.removeClass("disabled running");
				}
			});
		});
	}

	initCreateConversation() {
		$(document).on('click', '.js_create-conversation', e => {
			e.preventDefault()

			const el = $(e.target)
			const issue = el.data('issue')

			$.LoadingOverlay('show')
			$.ajax({
				url: '/conversations',
				data: {
					entity_id: el.data('order-id'),
					entity: 'orders',
					conversation_status: 'open',
					conversation_type: 'chat',
					subject: issue.name,
					issue_id: issue.id
				},
				dataType: 'JSON',
				method: 'POST',
				success: response => {
					if (response.code === 201)
						this.getConversation(response.data.conversations.id)
				},
				complete: () => {},
				error: () => {
					$.LoadingOverlay('hide')
				}
			});
		})
	}

	getConversation(id, page) {
		$.ajax({
			url: `/conversations/${id}`,
			method: 'GET',
			data: {
				conversation_id: id,
				order_by: 'created_at',
				dir: -1,
				page: page || null
			},
			success: html => {
				if (page) {
					const firstMsg = $(".msg:first"),
						curOffset = firstMsg.offset().top - $(".chat-msg").scrollTop(),
						messages = $(html).find(".msg");

					if (messages.length) {
						messages.prependTo(".chat-msg");
						$(".chat-msg").scrollTop(firstMsg.offset().top - curOffset);
						$(".chat-inputarea").find("input[name='page']").val(page);
					}
				} else {
					$('.chat').remove()
					$('.content').append(html)
					this.initChatSocket()
				}
			},
			complete: () => {
				$.LoadingOverlay('hide')
				$('#js_order-help-modal').modal('hide')
			}
		});
	}

	createMessage(message) {
		const conversationId = $(".chat-inputarea").find("input[name='conversation-id']").val()

		$.ajax({
			url: `/conversations/${conversationId}/message`,
			method: 'POST',
			data: {
				conversation_id: conversationId,
				message
			}
		});
	}

	initChatSocket() {
		clearInterval(this.chatSocketInterval)

		if (this.chatSocket && this.chatSocket.readyState)
			this.chatSocket.close();

		this.chatSocket = new WebSocket($(".chat-inputarea").data('url'))

		this.chatSocket.onmessage = (event) => {
			const data = JSON.parse(event.data)

			if (data.type === 'message') {
				this.appendMessage("left", data.text)
			} else if (data.type === 'typing') {
				// TODO:
			}
		}

		this.chatSocket.onerror = err => console.error(err) // eslint-disable-line
		this.chatSocketInterval = setInterval(() => {
			if (this.chatSocket.readyState) {
				this.chatSocket.send(JSON.stringify({ type: 'heartbeat' }))
			}
		}, 10000)

		$(".chat-msg").scrollTop($(".chat-msg").prop("scrollHeight"));
		$(".chat-msg").on("scroll", e => {
			var $this = $(e.currentTarget);

			if (($this.scrollTop() + $this.outerHeight()) / $this.prop("scrollHeight") * 100 < 90)
				$(".msg-scroll-bottom").fadeIn(500);
			else
				$(".msg-scroll-bottom").fadeOut(500);

			if ($this.scrollTop() === 0) {
				var form = $(".chat-inputarea"),
					page = parseInt(form.find("input[name='page']").val()) + 1,
					conversationId = form.find("input[name='conversation-id']").val();

				this.getConversation(conversationId, page)
			}
		});
	}

	initChatForm() {
		$(document).on("click", ".chat-close", e => {
			e.preventDefault();

			$(e.currentTarget).closest(".chat").remove();
			clearInterval(this.chatSocketInterval);
			this.chatSocket.close();
		});

		$(document).on("keypress", ".chat-input", () => {
			this.chatSocket.send(JSON.stringify({ type: 'typing' }))
		});

		$(document).on("submit", ".chat-inputarea", e => {
			e.preventDefault();

			const chatInput = $(e.currentTarget).find(".chat-input");
			const chatMsg = chatInput.val();

			if (chatMsg) {
				this.createMessage(chatMsg);
				this.appendMessage("right", chatMsg);
				this.chatSocket.send(JSON.stringify({
					type: 'message',
					text: chatMsg
				}));
				chatInput.val("");
			}
		});

		$(document).on("click", ".msg-scroll-bottom", () => {
			var chatsBlock = $(".chat-msg");

			chatsBlock.animate({
				scrollTop: chatsBlock.prop("scrollHeight"),
			}, 500);
		});
	}

	appendMessage(side, msg) {
		var msgEl = $(".msg:first").clone();

		msgEl.removeClass(`${side === "left" ? "right" : "left"}-msg`).addClass(`${side}-msg`)
			.find(".msg-text").text(msg);

		setTimeout(() => {
			msgEl.appendTo($(".chat-msg"));
			$(".chat-msg").scrollTop($(".chat-msg").prop("scrollHeight"));

			if ($(".chat").hasClass("d-none"))
				$("#chat-btn").trigger("click");
		}, 300);
	}

	initPagination() {
		Common.initPagination({
			parentSelector: '#js_orders',
			childSelector: '.list-group-item',
			ajaxProperties: {
				type: 'get',
				beforeSend(xhr, settings) {
					settings.url = '/orders?' + $.param({
						page: parseInt($('#js_orders .pagination').data('page')) + 1
					})
				},
			}
		})
	}
}
