import Form from "../../../assets/js/form";
import Common from "../../../assets/js/common";

export default class ChangePassword extends Form {
	constructor() {
		super();
		this.formEl = "#js_change-password-form";
		this.btnEl = "#js_change-password-submit-btn";
		this.rules = {
			current_password: {
				required: true
			},
			password: {
				required: true
			},
			password_confirmation: {
				required: true
			}
		};
		this.initForm();
		this.onModalDismiss();
	}

	onModalDismiss() {
		$(document).on('hidden.bs.modal', "#js_change-password", () => {
			Common.resetForms(this.formEl);
		});
	}

	successCallback() {
		Common.growlNotification({
			title: 'Success',
			description: 'Password changed successfully.',
			type: 'success',
			position: 'top-right',
			closeTimeout: 2000
		});
		$("#js_change-password").modal("hide");
	}
}
