import GrowlNotification from "./vendors/growl";
import "gasparesganga-jquery-loading-overlay";

$.fn.isInViewport = function() {
	const el = $(this);

	if (el.length) {
		const elementTop = el.offset().top;
		const elementBottom = elementTop + el.outerHeight();
		const viewportTop = $(window).scrollTop();
		const viewportBottom = viewportTop + $(window).height();

		return elementBottom > viewportTop && elementTop < viewportBottom;
	}

	return false
};

$.ajaxSetup({
	headers: {
		"X-CSRF-Token": $("meta[name='csrf-token']").attr("content")
	}
});

export default class Common {
	static popupWindow(url, title, w, h) {
		var left = (screen.width/2)-(w/2);
		var top = (screen.height/2)-(h/2);
		return window.open(
			url,
			title,
			`toolbar=no,
			location=no,
			directories=no,
			status=no,
			menubar=no,
			scrollbars=no,
			resizable=no,
			copyhistory=no,
			width='${w}',
			height='${h}',
			top='${top}',
			left='${left}`
		);
	}

	static skeletonLoading(el, show) {
		if (show) {
			el.find(".skeleton-content").addClass("d-none");
			el.find(".skeleton-loading").removeClass("d-none");
		} else {
			el.find(".skeleton-content").removeClass("d-none");
			el.find(".skeleton-loading").addClass("d-none");
		}
	}

	static loadContent(options) {
		let parent = options.parent;
		let loading;
		if (parent.attr("data-skeleton")) {
			loading = parent.find(".skeleton-loading");
			parent = parent.find(".skeleton-content");
		}
		const ajaxOptions = {
			url: options.url,
			data: options.data ? options.data: {},
			method: options.method ? options.method: "GET",
			success: (html) => {
				if (!options.dropdown)
					parent.removeClass("d-none");
				parent.html(html).promise().done(() => {
					if (typeof options.success === "function") {
						options.success();
					}
				});
			},
			complete: () => {
				if (loading) {
					loading.addClass("d-none");
				}
			},
			error: () => {
				Common.growlNotification({
					title: 'Oops!',
					description: 'Unable to load the content',
					type: 'error',
					position: 'top-center',
					closeTimeout: 2000
				});
			}
		};

		if (options.processData) {
			ajaxOptions.processData = options.processData;
		}

		if (options.contentType) {
			ajaxOptions.contentType = options.contentType;
		}

		$.ajax(ajaxOptions);
	}

	static growlNotification(options) {
		GrowlNotification.closeAll();
		GrowlNotification.notify(options);
	}

	static oops(errorMsg, position) {
		Common.growlNotification({
			title: 'Oops!',
			description: errorMsg ? errorMsg : 'Something went wrong',
			type: 'error',
			position: position ? position : 'top-right',
			closeTimeout: 3000
		});
	}

	static resetForms(el) {
		$(el).each((i,v) => {
			$(v)[0].reset();
			$(v).validate().resetForm();
		});
	}

	static loadModalFormContent(options) {
		const onShown = (el) => {
			el.on("shown.bs.modal", ()=> {
				if (typeof options.appended === "function") {
					options.appended();
				}
			});
		}

		const onHidden = (el) => {
			el.on("hidden.bs.modal", ()=> {
				el.remove();
			});
		}

		const ajaxProperties = {
			url: options.url,
			method: options.method ? "POST": "GET",
			success: (html) => {
				if (typeof options.success === "function") {
					options.success();
				}
				const modalEl = $(html);
				modalEl.modal("show");
				onShown(modalEl);
				onHidden(modalEl);
			},
			error: () => {
				if (typeof options.error === "function") {
					options.error();
				}

				Common.growlNotification({
					title: 'Oops!',
					description: 'Unable to load the content',
					type: 'error',
					position: 'top-center',
					closeTimeout: 2000
				});
			}
		};

		if (options.data) {
			ajaxProperties.data = options.data;
		}

		$.ajax(ajaxProperties);
	}

	static initPagination(options) {
		let timeout = null
		let paginating = false

		options.ajaxProperties = {
			...options.ajaxProperties,
			success(html) {
				html = $(html)

				const paginationEl = html.filter('.pagination')
				const paginationItems = html.find(options.childSelector)

				if (paginationItems.length) {
					$(`${options.parentSelector} .pagination-items`).append(paginationItems)
				}

				if (paginationEl.length) {
					$(`${options.parentSelector} .pagination`).replaceWith(paginationEl)
				} else {
					$(`${options.parentSelector} .pagination`).remove()
				}

				paginating = false
			},
			error() {
				paginating = false
				Common.oops()
			}
		}

		$(window).scroll(() => {
			clearTimeout(timeout)
			timeout = setTimeout(() => {
				const paginationEl = $(`${options.parentSelector} .pagination`)

				if (!paginating && paginationEl.isInViewport()) {
					paginating = true
					$.ajax(options.ajaxProperties)
				}
			}, 250)
		})
	}
}
