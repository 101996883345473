import moment from "moment"
import Common from "../../../assets/js/common";

export default class List {
	constructor() {
		if ($("#js_favourites").length) {
			this.loadFavourites();
		}
	}

	loadFavourites() {
		Common.loadContent({
			url: "/customer-favourites",
			data: {
				location_id: localStorage.getItem('selectedStallId'),
				only_favourite: 1,
				is_active: 1,
				date: moment().format('YYYY-MM-DD HH:mm:ss')
			},
			parent: $("#js_favourites")
		});
	}
}
