import "../scss/index.scss";
import "bootstrap";
import Geolocation from "./geolocation";
import "../../modules/auth/js/index";
import "../../modules/locations/js/index";
import "../../modules/items/js/index";
import "../../modules/orders/js/index";
import "../../modules/customers/js/index";
import "../../modules/customer-addresses/js/index";
import "../../modules/customer-favourites/js/index";
import AOS from "aos";

AOS.init();
new Geolocation();

$(document).on("keypress", "input[type='tel']", (evt) => {
	evt = (evt) ? evt : window.event;
	var charCode = (evt.which) ? evt.which : evt.keyCode;

	if (charCode > 31 && (charCode < 48 || charCode > 57)) {
		return false;
	}
	
	return true;
});
