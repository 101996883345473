import Common from "../../../assets/js/common";
import ExForm from "./form";

export default class List {
	constructor() {
		if ($("#js_delivery-addresses").length) {
			this.loadDeliveryAddresses();
		}
		if ($("#js_addresses").length) {
			this.loadAddresses();
		}
		this.onClickDeleteAddress();
		this.onClickEditAddress();
	}

	loadDeliveryAddresses() {
		Common.loadContent({
			url: "/customer-addresses/delivery",
			parent: $("#js_delivery-addresses"),
			success: () => {

			}
		});
	}

	loadAddresses() {
		Common.loadContent({
			url: "/customer-addresses",
			parent: $("#js_addresses"),
			success: () => {

			}
		});
	}

	onClickEditAddress() {
		$(document).on("click",".js_edit-address", (e) => {
			e.preventDefault();
			const el = $(e.currentTarget);
			el.addClass("disabled running");
			Common.loadModalFormContent({
				url: el.attr("data-url"),
				appended: () => {
					new ExForm();
				},
				success: () => {
					el.removeClass("disabled running");
				},
				error: () => {
					el.removeClass("disabled running");
				}
			});
		});
	}

	onClickDeleteAddress() {
		$(document).on("click",".js_delete-address", (e) => {
			e.preventDefault();
			const el = $(e.currentTarget);
			const url = el.attr("data-url");
			Common.growlNotification({
				type: "default",
				title: 'Delete',
				description: 'Are you sure you want to delete this address?',
				showButtons: true,
				position: 'top-center',
				buttons: {
					action: {
						text: 'Ok',
						callback: () => {
							el.addClass("disabled running");
							this.deleteAddress(url, el)
						}
					},
					cancel: {
						text: 'Cancel',
						callback: function() {}
					}
				}
			});
		});
	}

	deleteAddress(url, btn) {
		$.ajax({
			url: url,
			method: 'delete',
			success: () => {
				this.loadAddresses();
			},
			complete: () => {
				btn.removeClass("disabled running");
			},
			error: () => {

			}
		})
	}
}
