import Form from "../../../assets/js/form";
import Common from "../../../assets/js/common";

export default class ChangeEmail extends Form {
	constructor() {
		super();
		this.formEl = "#js_change-email-form";
		this.btnEl = "#js_change-email-submit-btn";
		this.rules = {
			email: {
				required: true,
				email: true
			}
		};
		this.initForm();
		this.onModalDismiss();
	}

	onModalDismiss() {
		$(document).on('hidden.bs.modal', "#js_change-email", () => {
			Common.resetForms(this.formEl);
		});
	}

	successCallback() {
		$.ajax({
			url: '/auth/send-email',
			method: 'post',
			data: {
				to: [ $('#js_change-email-form input[name="email"]').val() ],
				subject: 'Confirm Your Email Address'
			},
			dataType: 'JSON'
		});

		Common.growlNotification({
			title: 'Success',
			description: 'Mail has been to sent to your Email Address for verification.',
			type: 'success',
			position: 'top-right',
			closeTimeout: 2000
		});
		
		$("#js_change-email").modal("hide");
	}
}
