import GMaps from "gmaps";
import { v4 as uuidv4 } from 'uuid';
import Form from "../../../assets/js/form";
import Common from "../../../assets/js/common";

export default class ExForm extends Form {
	constructor() {
		super();
		this.token = uuidv4();
		this.formEl = "#js_customer-address-form";
		this.btnEl = "#js_customer-address-submit-btn";
		this.rules = {
			"address[lat]": {
				required: true
			},
			"address[lng]": {
				required: true
			},
			"address[line]": {
				required: true
			},
			"address[landmark]": {
				required: true
			},
			"annotation": {
				required: true
			},
			"address[pincode]": {
				required: true
			}
		};
		this.initForm();
		this.onModalShow();
		this.initAutocomplete();
	}

	generateToken() {
		this.token = uuidv4();
	}

	onModalShow() {
		$(document).on('shown.bs.modal', "#js_customer-address-modal", () => {
			this.initMap();
		});
	}

	initMap() {
		const lat = $(`${this.formEl} input[name='address[lat]']`).val();
		const lng = $(`${this.formEl} input[name='address[lng]']`).val();

		this.map = new GMaps({
			div: '#js_customer-address-map',
			lat: lat,
			lng: lng
		});

		this.map.addMarker({
			lat: lat,
			lng: lng,
			draggable: true,
			dragend: (e) => {
				$(`${this.formEl} input[name='address[lat]']`).val(e.latLng.lat());
				$(`${this.formEl} input[name='address[lng]']`).val(e.latLng.lng());
			}
		});
	}

	initAutocomplete() {
		let timer = null

		$('.places-autocomplete').on('keyup', e => {
			clearTimeout(timer)
			timer = setTimeout(() => {
				const el = $(e.currentTarget)

				if (el.val()) {
					el.closest('.input-group').addClass('running')
					$.ajax({
						url:"/places-autocomplete",
						data: {
							input: el.val(),
							token: this.token,
							class_name: 'js_address-place'
						},
						success: (html) => {
							el.parent().next().html(html)
						},
						complete: () => {
							el.closest('.input-group').removeClass('running')
						},
						error: () => {
							Common.oops()
						}
					})
				} else {
					el.parent().next().html('')
					this.generateToken()
				}
			}, 500)
		})

		$(document).on('click', '.js_address-place', e => {
			e.preventDefault()

			const el = $(e.currentTarget)

			el.closest('.places').html('')
			$('#js_customer-address-form').addClass('running')
			$.ajax({
				url:'/place/geocode',
				data: {
					place_id: el.data('obj').place_id
				},
				success: (response) => {
					$('#js_customer-address-form input[name="address[lat]"]').val(response.lat)
					$('#js_customer-address-form input[name="address[lng]"]').val(response.lng)
					$('#js_customer-address-form input[name="address[city]"]').val(response.city)
					$('#js_customer-address-form input[name="address[area]"]').val(response.area)
					$('#js_customer-address-form input[name="address[line]"]').val(response.line)
					$('#js_customer-address-form input[name="address[pincode]"]').val(response.pincode)
					this.initMap()
				},
				complete: () => {
					$('#js_customer-address-form').removeClass('running')
				},
				error: () => {
					Common.oops()
				}
			})

			this.generateToken()
		})
	}
}
