import Form from "../../../assets/js/form";
import Common from "../../../assets/js/common";

export default class ResetPasswordForm extends Form {
	constructor() {
		super();
		this.formEl = "#js_reset-password-form";
		this.btnEl = "#js_reset-password-submit-btn";
		this.rules = {
			password: {
				required: true
			},
			password_confirmation: {
				required: true
			}
		};
		this.initForm();
	}

	successCallback() {
		$(this.formEl)[0].reset();
		$("#js_auth-modal .modal-content").addClass("d-none");
		$("#js_login").removeClass("d-none");
		Common.growlNotification({
			title: 'Success',
			description: 'Password resetted successfully.',
			type: 'success',
			position: 'top-right',
			closeTimeout: 2000
		})
	}
}
