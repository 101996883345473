import Form from "../../../assets/js/form";
import Common from "../../../assets/js/common";

export default class ChangeInfo extends Form {
	constructor() {
		super();
		this.formEl = "#js_change-info-form";
		this.btnEl = "#js_change-info-submit-btn";
		this.rules = {
			name: {
				required: true
			}
		};
		this.initForm();
		this.onModalDismiss();
	}

	onModalDismiss() {
		$(document).on('hidden.bs.modal', "#js_change-info", () => {
			Common.resetForms(this.formEl);
		});
	}

	successCallback() {
		window.location.reload();
	}
}
