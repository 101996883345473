import Common from "../../../assets/js/common";
import Swiper from "swiper";
import moment from "moment"

export default class LocationList {
	constructor() {
		if ($("#js_nearby-locations").length) {
			Common.loadContent({
				url: "/locations/nearby",
				parent: $("#js_nearby-locations"),
				success: () => {
					if ($(".js_nearby-location").length) {
						new Swiper('.swiper-container', {
							slidesPerView: 'auto'
						});
						const location_id = $(".js_nearby-location").eq(0).attr("data-id");

						localStorage.setItem('selectedStallId', location_id)
						this.loadItems(location_id);
					}
				}
			});
		}

		this.onClickLocation();
		this.initPagination();
	}

	loadItems(location_id) {
		const data = {
			date: moment().format('YYYY-MM-DD HH:mm:ss')
		};
		const cart = localStorage.getItem("cart");

		if (cart) {
			data.cart = JSON.parse(cart);
		}

		data.location_id = location_id;
		Common.loadContent({
			url: "/items",
			data: JSON.stringify(data),
			method: "POST",
			contentType: "application/json",
			processData: false,
			parent: $("#js_location-items"),
			success: () => {}
		});
	}

	initPagination() {
		Common.initPagination({
			parentSelector: '#js_location-items',
			childSelector: '.col-md-4',
			ajaxProperties: {
				url: '/items',
				type: 'POST',
				contentType: 'application/json',
				beforeSend(xhr, settings) {
					settings.data = JSON.stringify({
						location_id: localStorage.getItem('selectedStallId'),
						date: moment().format('YYYY-MM-DD HH:mm:ss'),
						cart: JSON.parse(localStorage.getItem('cart')),
						page: parseInt($('#js_location-items .pagination').data('page')) + 1
					})
				},
			}
		})
	}

	onClickLocation() {
		$(document).on("click",".js_nearby-location", (e) => {
			const el = $(e.currentTarget);
			const location_id = el.attr("data-id");

			$(".js_nearby-location").removeClass("active");
			el.addClass("active");
			Common.skeletonLoading($("#js_location-items"), true);
			localStorage.setItem('selectedStallId', location_id)
			this.loadItems(location_id);
		});
	}
}
