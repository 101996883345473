import Cart from "./cart";
import List from "./list";
import Feedback from "./feedback";
import Checkout from "./checkout";
import ApplyCoupon from "./apply_coupon";
import Common from "../../../assets/js/common";

new Cart();
new List();
new Checkout();

if ($("#js_order-feedback-modal").length) {
	new Feedback();
}

if ($("#js_apply-coupon-modal").length) {
	new ApplyCoupon();
}

const url = new URL(window.location.href);
const status = url.searchParams.get('status');

if (status) {
	if (status == "F") {
		Common.growlNotification({
			title: 'Oops!',
			description: 'Transaction Failed',
			type: 'error',
			position: 'top-center',
			closeTimeout: 2000
		});
	}

	localStorage.removeItem("cart");
}
