import Form from "../../../assets/js/form";
import Common from "../../../assets/js/common";

export default class Rating extends Form {
	constructor() {
		super();
		this.formEl = "#js_order-feedback-form";
		this.btnEl = "#js_order-feedback-submit-btn";
		this.rules = {
			rating: {
				required: true
			}
		};

		this.initRatingModal();
		this.initForm();
		this.onModalDismiss();
	}

	initRatingModal() {
		$('#js_order-feedback-modal').on('show.bs.modal', (e) => {
			const el = $(e.currentTarget)
			const rel = $(e.relatedTarget)

			el.find('input[name="order_id"]').val(rel.data('order-id'));
			el.find('#location-name').text(rel.data('location-name'));
		}).on('hidden.bs.modal', (e) => {
			$(e.currentTarget).find('.btn-group-toggle .btn').removeClass('active')
		})
	}

	onModalDismiss() {
		$(document).on('hidden.bs.modal', "#js_order-feedback-modal", () => {
			Common.resetForms(this.formEl);
		});
	}
}
