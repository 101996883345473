import Common from "../../../assets/js/common";

export default class Checkout {
	constructor() {
		this.onSelectDeliveryAddress();
		this.onClickDeliverySection();
		this.onClickPaymentBtn();
	}

	onSelectDeliveryAddress() {
		$(document).on("click", ".js_shipping-address", (e) => {
			e.preventDefault();

			const el = $(e.currentTarget);
			const card = el.closest('.js_checkout-delivery-select');
			const annotation = card.attr("data-annotation");
			const address = card.attr("data-address");
			const parent = card.closest("#js_checkout-delivery-address-section");

			el.prop('disabled', true)
			this.deliveryCheck(el, () => {
				parent.addClass("done");
				parent.attr("data-id", el.attr("data-id"));
				parent.find(".selected").removeClass("d-none");
				$("#js_delivery-annotation").text(annotation);
				$("#js_delivery-address").text(address);
				parent.find(".not-selected").addClass("d-none");
				$("#js_checkout-payment-section .not-selected").addClass("d-none");
				$("#js_checkout-payment-section .selected").removeClass("d-none");
			});

		});
	}

	onClickDeliverySection() {
		$(document).on("click", "#js_checkout-delivery-address-section.done",(e) => {
			e.preventDefault();
			const el = $(e.currentTarget);
			$("#js_checkout-delivery-address-section").removeAttr("data-id");
			el.find(".selected").addClass("d-none");
			el.find(".not-selected").removeClass("d-none");
			el.removeClass("done");
			$("#js_checkout-payment-section .not-selected").removeClass("d-none");
			$("#js_checkout-payment-section .selected").addClass("d-none");
		});
	}

	onClickPaymentBtn() {
		$(document).on("click", ".js_pay",(e) => {
			e.preventDefault();
			const el = $(e.currentTarget);
			const payment_method_id = el.attr("data-id");
			$.LoadingOverlay("show", {
				text: payment_method_id === 'cod' ? 'Order is being placed' : 'Initailizing payment gateway'
			});
			this.processOrder(payment_method_id, el);
		});

		$(document).on('click', '#js_pg-modal button', (e) => {
			e.preventDefault()

			const el = $(e.currentTarget)

			$('#js_pg-modal').modal('hide')
			$.LoadingOverlay('show', { text: 'Waiting for payment gateway response' })
			this.monitorPopWindow(el.data('id'), el.data('url'))
		})

		$(document).on('hidden.bs.modal', '#js_pg-modal', e => {
			$(e.currentTarget).remove()
		})
	}

	processOrder(payment_method_id, el) {
		let cart = localStorage.getItem("cart");

		if (cart) {
			el.addClass("disabled running");
			cart = JSON.parse(cart);
			cart.address_id = $("#js_checkout-delivery-address-section.done").attr("data-id");
			cart.payment_method_id = payment_method_id;

			$.ajax({
				url: "/orders/process",
				data: JSON.stringify(cart),
				processData: false,
				method: "POST",
				contentType: "application/json",
				dataType: payment_method_id === "cod" ? "JSON" : null,
				success: (data) => {
					if (payment_method_id === "cod") {
						localStorage.removeItem("cart");
						window.location.href = `/account/orders?track=${data.id}`
					} else {
						$.LoadingOverlay('hide')
						$('body').append(data)
						$('#js_pg-modal').modal('show')
					}
				},
				complete: (xhr) => {
					const data = xhr.responseJSON;
					if (payment_method_id === "cod" && data.error) {
						$.LoadingOverlay("hide");
						el.removeClass("disabled running");
					}
				},
				error: (xhr) => {
					$.LoadingOverlay("hide");
					el.removeClass("disabled running");
					Common.oops(xhr.responseJSON.message);
				}
			});
		}
	}

	deliveryCheck(el, callback) {
		let cart = localStorage.getItem("cart");
		if (cart) {
			el.addClass("disabled running");
			cart = JSON.parse(cart);
			cart.address_id = el.attr("data-id");
			$.ajax({
				url: "/orders/delivery-check",
				data: JSON.stringify(cart),
				processData: false,
				method: "POST",
				contentType: "application/json",
				dataType: "JSON",
				success: () => {
					callback();
				},
				complete: () => {
					el.removeClass("disabled running");
					el.prop('disabled', false)
				},
				error: (xhr) => {
					const data = xhr.responseJSON;
					if (data.error) {
						Common.oops(data.error);
					}
				}
			});
		}
	}

	monitorPopWindow(order_id, url) {
		window.popupWindowResponse = (success, url) => {
			if (window.popupTimeout) {
				clearTimeout(window.popupTimeout);
			}

			if (window.popupInterval) {
				clearInterval(window.popupInterval);
			}

			if (success === "true") {
				localStorage.removeItem("cart");
				window.location.href = url;
			} else {
				this.showPaymentGatewayError("Payment failed,  Please retry again.");
			}
		};

		const popupWindow = this.popupCenter(url, "Payment Gateway", 500, 500);

		window.popupInterval = setInterval(() => {
			if (popupWindow.closed) {
				clearInterval(window.popupInterval);

				if (window.popupTimeout) {
					clearTimeout(window.popupTimeout);
				}

				$.ajax({
					url: `/orders/worldline/status/${order_id}`,
					dataType: "JSON",
					success: () => {
						localStorage.removeItem("cart");
						window.location.href = `/account/orders/?track=${order_id}`;
					},
					error: () => {
						this.showPaymentGatewayError("Payment failed,  Please retry again.");
					}
				});
			}
		}, 1000);

		window.popupTimeout = setTimeout(() => {
			clearTimeout(window.popupTimeout);
			popupWindow.close();
		}, 5 * 60 * 1000);
	}

	showPaymentGatewayError(text) {
		$.LoadingOverlay("hide");
		$(".disabled.running").removeClass("disabled running");
		Common.oops(text);
	}

	popupCenter(url, title, w, h, opts) {
		var _innerOpts = '';
		if (opts !== null && typeof opts === 'object') {
			for (var p in opts) {
				_innerOpts += p + '=' + opts[p] + ',';
			}
		}
		var dualScreenLeft = window.screenLeft != undefined ? window.screenLeft : screen.left;
		var dualScreenTop = window.screenTop != undefined ? window.screenTop : screen.top;

		var width = window.innerWidth ? window.innerWidth : (
			document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width
		);
		var height = window.innerHeight ? window.innerHeight : (
			document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height
		);

		var left = ((width / 2) - (w / 2)) + dualScreenLeft;
		var top = ((height / 2) - (h / 2)) + dualScreenTop;
		var newWindow = window.open(
			url,
			title,
			_innerOpts + ' width=' + w + ', height=' + h + ', top=' + top + ', left=' + left
		);

		if (window.focus) {
			newWindow.focus();
		}
		return newWindow;
	}
}
