import "magnific-popup";
import moment from "moment"
import Cart from "../../orders/js/cart";
import Common from "../../../assets/js/common";

export default class List {
	constructor() {
		this.onClickFavourite();
		this.onClickPlusItem();
		this.onClickMinusItem();
		this.onShowItemModal();
		this.onHideItemModal();
		this.onItemImageClick();
		this.onItemClick();
		this.onClickStall();
	}

	onItemImageClick() {
		$(document).on("click",'.js_item-image', (e) => {
			const el = $(e.currentTarget);
			if ($(e.target).parent().hasClass('close')) {
				return;
			}
			$.magnificPopup.open({
				items: JSON.parse(el.attr('data-images')),
				gallery: {
					enabled: true,
					preload: [ 0,2 ]
				},
				type: 'image' // this is default type
			});
		});
	}

	onItemClick() {
		$(document).on("click", '.js_item', (e) => {
			e.preventDefault();

			const el = $(e.currentTarget);
			const modalEl = $(el.attr("data-target"));

			modalEl.modal('show', el);
		});
	}

	onClickFavourite() {
		let processing = false

		$(document).on('click', '.favourite', e => {
			e.stopPropagation();
			e.preventDefault();

			const el = $(e.currentTarget)
			const itemId = el.data('item-id')

			if (!processing && itemId) {
				const type = el.attr('data-id') ? 'delete' : 'post'

				processing = true
				$.ajax({
					url: '/customer-favourites' + (type === 'delete' ? `/${el.attr('data-id')}` : ''),
					type,
					data: type === 'post' ? JSON.stringify({
						item_id: itemId
					}) : null,
					contentType: 'application/json',
					success(response) {
						if (response.status === 'success') {
							if (response.id)
								el.attr('data-id', response.id)
							else
								el.removeAttr('data-id')

							el.toggleClass('active')
							el.find('i').toggleClass('las lar')
						}
					},
					complete() {
						processing = false
					},
					error() {
						Common.oops()
					}
				})
			}
		});
	}

	onClickPlusItem() {
		$(document).on("click", ".js_plus-item", (e) => {
			e.stopPropagation();
			e.preventDefault();

			const el = $(e.currentTarget);

			if (this.checkDifferentLocation(el)) {
				Common.growlNotification({
					type: "success",
					title: 'Items already in cart',
					description: `Your cart contains items from other location.
					Would you like to reset your cart for adding items from this location?`,
					showButtons: true,
					position: 'top-center',
					buttons: {
						action: {
							text: 'YES, START AFRESH',
							callback: () => {
								localStorage.removeItem("cart");
								this.addItem(el);
							}
						},
						cancel: {
							text: 'Cancel',
							callback: () => {

							}
						}
					},
				});
			} else {
				this.addItem(el);
			}
		});
	}

	addItem(el) {
		const cart = JSON.parse(localStorage.getItem('cart'))
		const incrementQty = (el.data("unit") === 'count' ? 1 : parseFloat(window.settings.quantity))

		if (cart) {
			const cartWeight = Object.values(cart.items).reduce((sum, item) => {
				sum += item.weight
				return parseFloat(sum.toFixed(3))
			}, 0) + (parseFloat(el.data("weight") * incrementQty));

			if (cartWeight > window.settings.order_weight_limit) {
				return Common.growlNotification({
					type: 'warning',
					title: 'Alert',
					description: `You can only order upto ${
						window.settings.order_weight_limit
					} KG of items per order.`,
					position: 'top-center'
				});
			}
		}

		const item_id = el.attr("data-id");
		const location_id = el.attr("data-location");
		const multi = el.attr("data-multi");

		if (multi) {
			const modalEl = $(el.attr("data-target"));
			modalEl.modal('show',el);
		} else {
			if (el.hasClass("add")) {
				el.hide();
				el.parent().find(".change").show();
			}

			const qtyEl = el.parent().find(".qty");
			let qty = parseFloat(qtyEl.text());

			qty += incrementQty;
			qtyEl.text(qty);
			this.processCart(location_id, item_id, el.attr("data-url"), qty, el);
		}
	}

	onClickMinusItem() {
		$(document).on("click", ".js_minus-item", (e) => {
			e.stopPropagation();
			e.preventDefault();

			const el = $(e.currentTarget);
			const item_id = el.attr("data-id");
			const location_id = el.attr("data-location");
			const qtyEl = el.parent().find(".qty");
			const qty = parseFloat(qtyEl.text()) - (
				el.data("unit") === 'count' ? 1 : parseFloat(window.settings.quantity)
			);

			if (qty == 0) {
				el.closest(".cart-btn").find(".add").show();
				el.parent().hide();
			}

			const url = el.attr("data-url");

			qtyEl.text(qty);
			this.processCart(location_id, item_id, url, qty, el);
		});
	}

	checkDifferentLocation(el) {
		if ($("#js_checkout-cart").length) {
			return false;
		}

		const location_id = el.attr("data-location");
		let cart = localStorage.getItem("cart");

		if (cart) {
			cart = JSON.parse(cart);

			if (parseInt(location_id) != parseInt(cart.location_id)) {
				return true;
			}
		}

		return false;
	}

	processCart(location_id, item_id, url, qty, el) {
		$.LoadingOverlay('show');

		if (qty >= window.settings.quantity) {
			$.ajax({
				url: url,
				data: {
					json: 1
				},
				success: (data) => {
					if (data.inventory.track_inventory && qty > data.inventory.stock) {
						qty = Math.floor(data.inventory.stock * 2) / 2;

						if (qty >= window.settings.quantity) {
							el.parent().find('.qty').text(qty);
							Cart.addToLocalStorage(data, qty, location_id);
						} else {
							el.closest(".cart-btn").find(".add").show();
							el.parent().hide();
							Cart.removeFromLocalStorage(item_id, location_id);
						}

						Common.growlNotification({
							type: 'warning',
							title: 'Stock Alert',
							description: `We have only ${data.inventory.stock} ${data.name} left.`,
							position: 'top-center'
						});
					} else {
						Cart.addToLocalStorage(data, qty, location_id);
					}
				},
				complete: () => {
					$.LoadingOverlay('hide');
				},
				error: () => {
					Common.oops();
				}
			});
		} else {
			Cart.removeFromLocalStorage(item_id, location_id);
			$.LoadingOverlay('hide');
		}
	}

	onShowItemModal() {
		$(document).on("shown.bs.modal", "#js_item-modal", (e) => {
			const el = $(e.relatedTarget);
			const url = el.attr("data-url");
			const cart = localStorage.getItem("cart");
			const data = {
				date: moment().format('YYYY-MM-DD HH:mm:ss'),
				location_id: localStorage.getItem('selectedStallId')
			};

			if (cart) {
				data.cart = JSON.parse(cart);
			}

			Common.loadContent({
				url: url,
				data: JSON.stringify(data),
				method: "POST",
				contentType: "application/json",
				processData: false,
				parent: $("#js_item-modal"),
				success: () => {
					this.loadItemStalls();
				}
			});
		});
	}

	loadItemStalls() {
		Common.loadContent({
			url: $("#js_item-stalls").attr("data-url"),
			parent: $("#js_item-stalls"),
			success: () => {}
		});
	}

	onClickStall() {
		$(document).on("click", ".js_stall", (e) => {
			const el = $(e.currentTarget);
			const data = JSON.parse(el.attr("data-obj"));

			data.date = moment().format('YYYY-MM-DD HH:mm:ss')
			data.cart = JSON.parse(localStorage.getItem('cart'))
			data.location_id = data.id
			Common.skeletonLoading($("#js_item-modal"), true)
			Common.loadContent({
				url: el.attr("data-url"),
				data: JSON.stringify(data),
				method: "POST",
				contentType: "application/json",
				processData: false,
				parent: $("#js_item-modal")
			});
		});
	}

	onHideItemModal() {
		$(document).on("hidden.bs.modal", "#js_item-modal", (e) => {
			const el = $(e.currentTarget);
			el.find(".skeleton-loading").removeClass("d-none");
			el.find(".skeleton-content").addClass("d-none");
		});
	}
}
