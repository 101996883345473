import Form from "../../../assets/js/form";
import Common from "../../../assets/js/common";

export default class Login extends Form {
	constructor(otp) {
		super();
		this.otp = otp;
		this.formEl = "#js_login-form";
		this.btnEl = "#js_login-submit-btn";
		this.rules = {
			phone: {
				required: true,
				regex: "^[6-9][0-9]{9}$"
			},
			password: {
				required: true
			},
		};
		this.initForm();
		this.onClickForgotPassword();
		this.onClickSocialLogin();
		this.onWindowResult();
	}

	initForm() {
		$(this.formEl).on("submit", () => {
			return false;
		});
		$(`${this.formEl} input`).keydown(e => {
			if (e.keyCode == 13 && !$(this.btnEl).hasClass("disabled"))
				this.validateLogin();
		});
		this.onClickSubmitBtn();
		this.validator[this.formEl] = this.initFormValidation();
	}

	onClickSubmitBtn() {
		const btnEl = $(this.btnEl);
		btnEl.on("click", e => {
			e.preventDefault();

			if (!btnEl.hasClass("disabled")) {
				this.validateLogin();
			}
		});
	}

	validateLogin() {
		const startTime = localStorage.getItem("l");
		if (startTime) {
			const endTime = new Date();
			let timeDiff = endTime - startTime;
			timeDiff /= 1000;

			const seconds = Math.round(timeDiff);
			if (seconds < 60 *10) {
				let remaining = Math.round(10 - (seconds/60));
				remaining = remaining == 0 ? 1: remaining;
				this.validator[this.formEl].showErrors({
					phone: `Login Attempt has exceeded 5 times. Please wait for about ${remaining} mins`
				});
			} else {
				this.submitForm();
			}
		} else {
			this.submitForm();
		}
	}

	errorCallback(data) {
		if (data.lock_login) {
			localStorage.setItem("l",new Date().valueOf());
		}
	}

	onWindowResult() {
		window.setSocialLoginData = (data) => {
			data = JSON.parse(data);
			if (data.status == "success") {
				this.successCallback(data);
			} else {
				Common.oops(data.message);
			}
		}
	}

	successCallback(data) {
		if (data.isMobileVerified === false) {
			this.otp.phone = $("#js_login-phone").val()
			this.otp.sendOtp()
			$("#js_auth-modal .modal-content").addClass("d-none");
			$("#js_otp").removeClass("d-none");
			$("#js_otp .js_otp-phone-number").text($("#js_login-phone").val());
			this.otp.startTimer(300);
		} else {
			window.location.reload();
		}
	}

	onClickSocialLogin() {
		$(document).on("click", ".js_auth-social-login", (e)=> {
			e.preventDefault();
			const el = $(e.currentTarget);
			const url = el.attr("data-url");
			this.socialWindow = Common.popupWindow(url, "Social Login", 600, 500);
		});
	}

	onClickForgotPassword() {
		$(document).on("click", "#js_forgot-link", (e)=> {
			this.otp.isResetPassword = true;
			e.preventDefault();
			const el = $(e.currentTarget);
			if (el.hasClass("disabled")) {
				return;
			}
			const btnEl = $(this.btnEl);
			const isValid = this.validator[this.formEl].element("#js_login-phone");
			if (isValid) {
				const phone = $("#js_login-phone").val()

				el.addClass("disabled");
				btnEl.addClass("disabled running");
				$.ajax({
					url: el.attr("data-url"),
					data: {
						phone
					},
					method: "POST",
					dataType: "JSON",
					success: (data) => {
						if (data.status == "success") {
							this.otp.phone = phone
							this.otp.sendOtp()
							$("#js_auth-modal .modal-content").addClass("d-none");
							$("#js_otp").removeClass("d-none");
							$("#js_otp .js_otp-phone-number").text($("#js_login-phone").val());
							this.otp.startTimer(300);
						}
					},
					complete: () => {
						btnEl.removeClass("disabled running");
						el.removeClass("disabled");
					},
					error: (xhr) => {
						const data = xhr.responseJSON;
						switch (xhr.status) {
							case 422:
								this.validator[this.formEl].showErrors(data.errors);
								break;
							case 500:
								Common.oops();
								break;
						}
					},
				})
			}
		});
	}
}
