import Common from "./common";
import { v4 as uuidv4 } from 'uuid';

export default class {
	constructor() {
		this.token = uuidv4();
		this.onClickLocateMe();
		this.onTypeDeliveryLocation();
		this.onClickPlace();
		this.onClickDeliverySearchChange();
		this.onClickDeliverySearchCancel();
	}

	generateToken() {
		this.token = uuidv4();
	}

	onClickLocateMe() {
		$(document).on("click", ".js_locate-me", (e) => {
			const el = $(e.currentTarget);
			el.addClass("disabled running");
			if (navigator.geolocation) {
				navigator.geolocation.getCurrentPosition((position) => {
					$.ajax({
						url:"/geocode",
						data: {
							latlng: `${position.coords.latitude},${position.coords.longitude}`
						},
						success: (data) => {
							el.removeClass("disabled running");
							if (data.status == "error") {
								Common.oops('Location information is unavailable.', 'top-left');
							} else {
								localStorage.setItem("userLocation", JSON.stringify(data));
								window.location.reload();
							}
						},
						complete: () => {
							el.removeClass("disabled running");
						},
						error: () => {
							Common.oops();
						}
					})
				}, (error) => {
					el.removeClass("disabled running");
					switch (error.code) {
						case error.PERMISSION_DENIED:
							Common.oops(
								`You have blocked Meengal from tracking your location.
								To use this, Change your location settings in browser.`,
								'top-left'
							);
							break;
						case error.POSITION_UNAVAILABLE:
							Common.oops('Location information is unavailable.', 'top-left');
							break;
						case error.TIMEOUT:
							Common.oops('The request to get user location timed out.', 'top-left');
							break;
						case error.UNKNOWN_ERROR:
							Common.oops();
							break;
					}
				});
			} else {
				Common.oops('Geolocation is not supported by this browser.', 'top-left');
			}
		});
	}

	onClickDeliverySearchChange() {
		$(document).on("click", "#js_delivery-location-change", (e) => {
			const el = $(e.currentTarget);

			el.next().removeClass("d-none");
			el.next().find("input").focus();
			el.addClass("d-none");
		});
	}

	onClickDeliverySearchCancel() {
		$(document).on("click","#js_delivery-location-cancel", (e) => {
			const el = $(e.currentTarget);
			$('#js_delivery-location').val("");
			this.clearAutocomplete();
			el.closest(".delivery-location-search").addClass("d-none");
			el.closest(".delivery-location-search").prev().removeClass("d-none");
		});
	}

	onTypeDeliveryLocation() {
		let timer = null
		const autocomplete = $('#js_delivery-location').attr("data-autocomplete");

		$('#js_delivery-location').keyup(() => {
			clearTimeout(timer)
			timer = setTimeout(() => {
				if ($('#js_delivery-location').val()) {
					$(".js_locate-me").addClass("disabled running");
					$.ajax({
						url:"/places-autocomplete",
						data: {
							input: $('#js_delivery-location').val(),
							token: this.token,
							class_name: 'js_place'
						},
						success: (html) => {
							$(autocomplete).removeClass("d-none");
							$(autocomplete).html(html);
						},
						complete: () => {
							$(".js_locate-me").removeClass("disabled running");
						},
						error: () => {
							Common.oops();
						}
					})
				} else {
					this.generateToken();
				}
			}, 500)
		});

		$('#js_delivery-location').focus(() => {
			if ($("#js_delivery-location-autocomplete").is(":visible")) {
				this.clearAutocomplete();
			}

			if (window.location.pathname === '/') {
				$('html, body').animate({
					scrollTop: $('#meengal-logo-home').offset().top - 20
				}, 'fast')
			}
		});

		$('#js_delivery-location').on('input', (e) => {
			const el = $(e.currentTarget);

			if (!el.val()) {
				this.clearAutocomplete();
				this.generateToken();
			}
		});
	}

	clearAutocomplete() {
		$("#js_delivery-location-autocomplete").html("");
		$("#js_delivery-location-autocomplete").addClass("d-none");
	}

	onClickPlace() {
		$(document).on("click", ".js_place", (e) => {
			e.preventDefault();

			const el = $(e.currentTarget);
			const jsonData = JSON.parse(el.attr("data-obj"));

			el.closest(".autocomplete").addClass("d-none");
			$(".js_locate-me").addClass("disabled running");
			$.ajax({
				url: "/set-delivery-location",
				data: {
					place_id: jsonData.place_id,
					token: this.token
				},
				dataType: "JSON",
				success: (data) => {
					localStorage.setItem("userLocation", JSON.stringify(data));
					window.location.reload();
				},
				complete: () => {
					$(".js_locate-me").removeClass("disabled running");
				},
				error: () => {
					Common.oops();
				}
			});

			$('#js_delivery-location').val('').trigger('input');
		});
	}
}
