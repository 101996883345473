import Form from "../../../assets/js/form";
import Common from "../../../assets/js/common";
import ChangeMobileOtpForm from "./otp";

export default class ChangeMobile extends Form {
	constructor() {
		super();
		this.formEl = "#js_change-mobile-form";
		this.btnEl = "#js_change-mobile-submit-btn";
		this.rules = {
			phone: {
				required: true,
				regex: "^[6-9][0-9]{9}$"
			}
		};
		this.initForm();
		this.onModalDismiss();
	}

	onModalDismiss() {
		$(document).on('hidden.bs.modal', "#js_change-mobile", () => {
			Common.resetForms(this.formEl);
			if (this.otp) {
				Common.resetForms(this.otp.formEl);
			}
			$("#js_change-mobile-otp").addClass("d-none");
			$("#js_change-mobile-content").removeClass("d-none");
		});
	}

	successCallback() {
		$("#js_change-mobile-otp").removeClass("d-none");
		$("#js_change-mobile-content").addClass("d-none");
		this.otp = new ChangeMobileOtpForm();
		this.otp.phone = $('#js_change-mobile-form input[name="phone"]').val()
		this.otp.sendOtp()
		this.otp.startTimer(300);
	}
}
