import "jquery-validation";
import Common from "./common";

export default class Form {
	constructor() {
		this.validator = [];
		this.formDataConcat = [];
	}

	initFormValidation() {
		return $(this.formEl).validate({
			ignore: [],
			focusInvalid: false,
			highlight: element => {
				if ($(element).parent(".hidden-group, .select-group").length) {
					$(element).parent().addClass("is-invalid");
				} else {
					$(element).addClass("is-invalid");
				}

				$(element).closest(".form-group").addClass("has-error");
			},
			unhighlight: element => {
				if ($(element).parent(".hidden-group, .select-group").length) {
					$(element).parent().removeClass("is-invalid");
				} else {
					$(element).removeClass("is-invalid");
				}

				const formGroup = $(element).closest(".form-group");

				formGroup.find(".invalid-feedback").remove();
				formGroup.removeClass("has-error");
			},
			errorElement: "span",
			errorClass: "invalid-feedback",
			errorPlacement: (error, element) => {
				if (element.parent(".input-group").length) {
					error.insertAfter(element.parent());
				} else if (element.parent(".hidden-group, .select-group").length) {
					error.insertAfter(element.parent());
				} else {
					error.insertAfter(element);
				}
			},
			rules: this.rules,
		});
	}

	initForm() {
		$(this.formEl).on("submit", () => {
			return false;
		});

		$(`${this.formEl} input`).keydown(e => {
			if (e.keyCode == 13 && !$(this.btnEl).hasClass("disabled"))
				this.submitForm();
		});
		this.onClickSubmitBtn();
		this.validator[this.formEl] = this.initFormValidation();
	}

	onClickSubmitBtn() {
		const btnEl = $(this.btnEl);
		btnEl.on("click", e => {
			e.preventDefault();

			if (!btnEl.hasClass("disabled"))
				this.submitForm();
		});
	}

	submitForm() {
		const formEl = $(this.formEl);
		const btnEl = $(this.btnEl);
		if (formEl.valid()) {
			btnEl.addClass("disabled running");
			let formData = formEl.serializeArray();
			if (this.formDataConcat) {
				formData = formData.concat(this.formDataConcat);
			}
			const ajaxProperties = {
				url: formEl.data("url"),
				data: formData,
				dataType: "JSON",
				method: formEl.attr("method") ? formEl.attr("method") : "POST",
				success: data => {
					btnEl.removeClass("disabled running");
					if (data.status == "success") {
						if (typeof this.successCallback === "function") {
							this.successCallback(data);
						} else if (data.redirect) {
							window.location.href = data.redirect;
						} else {
							window.location.reload();
						}
					}
				},
				complete: () => {
					btnEl.removeClass("disabled running");
				},
				error: (xhr) => {
					const data = xhr.responseJSON;
					if (typeof this.errorCallback === "function") {
						this.errorCallback(data);
					}
					switch (xhr.status) {
						case 422:
							try {
								this.validator[this.formEl].showErrors(data.errors);
							} catch (err) {
								if (Object.keys(data.errors).length) {
									Common.growlNotification({
										title: 'Validation Errors',
										description: "Fill all the Details",
										type: 'error',
										position: 'top-right',
										closeTimeout: 2000
									})
								}
							}
							break;
						default:
							Common.oops();
							break;
					}
				},
			};

			if (this.headers) {
				ajaxProperties.headers = this.headers;
			}
			if (this.formData) {
				ajaxProperties.data = new FormData(formEl[0]);
				ajaxProperties.processData = false;
				ajaxProperties.contentType = false;
			}

			$.ajax(ajaxProperties);
		}
	}
}
