import Form from "../../../assets/js/form";
import Common from "../../../assets/js/common";

export default class Register extends Form {
	constructor(otp) {
		super();
		this.otp = otp;
		this.formEl = "#js_register-form";
		this.btnEl = "#js_register-submit-btn";
		this.rules = {
			name: {
				required: true
			},
			phone: {
				required: true,
				regex: "^[6-9][0-9]{9}$"
			},
			email: {
				required: true
			},
			password: {
				required: true
			}
		};

		this.initForm();
		this.onWindowResult();
		this.onClickSocialConnect();
	}

	onWindowResult() {
		window.setSocialRegisterData = (data) => {
			data = JSON.parse(data);
			this.formDataConcat = [];

			if (data.status === "success") {
				$(this.formEl + " input[name='name']").val(data.name);
				$(this.formEl + " input[name='email']").val(data.email);

				if (data.provider == "google") {
					$(this.formEl + " input[name='gp_user_id']").val(data.id);
					this.formDataConcat.push({
						name: "gp_data[email]",
						value: data.email
					});
				} else if (data.provider == "facebook") {
					$(this.formEl + " input[name='fb_user_id']").val(data.id);
					this.formDataConcat.push({
						name: "fb_data[email]",
						value: data.email
					});
				}

				$(".js_auth-social-connect[data-provider='"+data.provider+"']").find(".connected")
					.removeClass("d-none");
				$(".js_auth-social-connect[data-provider='"+data.provider+"']").find(".not-connected")
					.addClass("d-none");
			} else {
				Common.oops(data.message)
			}
		}
	}

	onClickSocialConnect() {
		$(document).on("click", ".js_auth-social-connect", (e)=> {
			e.preventDefault();
			const el = $(e.currentTarget);
			const url = el.attr("data-url");
			this.socialWindow = Common.popupWindow(url, "Social Connect", 600, 500);
		});
	}

	successCallback(data) {
		$.ajax({
			url: '/auth/send-email',
			method: 'post',
			data: {
				to: [ $('#js_register-form input[name="email"]').val() ],
				subject: 'Confirm Your Email Address'
			},
			dataType: 'JSON'
		});

		if (!data.isMobileVerified) {
			this.otp.phone = $("#js_register-phone").val()
			this.otp.sendOtp()
			this.otp.formDataConcat = [ {
				name: "register",
				value: "1"
			} ];
			$("#js_auth-modal .modal-content").addClass("d-none");
			$("#js_otp").removeClass("d-none");
			$("#js_otp .js_otp-phone-number").text($("#js_register-phone").val());
			this.otp.startTimer(300);
		} else {
			window.location.reload();
		}
	}
}
